import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { LoginStudentResponse } from "./models/login-student-response";
import { firstValueFrom, Observable, tap } from "rxjs";
import { controllers } from "@shared/utils/controllers";
import { SelStudentService } from "@modules-student/dashboard-student/SEL/sel-student.service";
import { StateService } from "@shared/service/state.service";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthStudentService {
    private _httpClient = inject(HttpClient);
    private _stateService = inject(StateService);
    private controller = controllers.AuthStudent;
    public get studentName() {
        const studentSession = sessionStorage.getItem('student');
        if (!studentSession) return '';
        const student = JSON.parse(studentSession) as LoginStudentResponse;
        return student.studentName;
    };

    private _studentName = '';

    LoginStudent(email: string, password: string): Observable<LoginStudentResponse> {
        return this._httpClient.post<LoginStudentResponse>(`${this.controller}/LoginStudent`, { email, password }).pipe(
            tap(response => this.SaveStudentSession(response))
        );
    }

    LoginStudentEncryptPassword(email: string, password: string): Observable<LoginStudentResponse> {
        return this._httpClient.post<LoginStudentResponse>(`${this.controller}/LoginStudentEncryptPassword`, { email, password }).pipe(
            tap(response => this.SaveStudentSession(response))
        );
    }

    LoginStudentGoogle(token: string): Observable<LoginStudentResponse> {
        return this._httpClient.post<LoginStudentResponse>(`${this.controller}/LoginStudentGoogleAuth?credential=${token}`, {}).pipe(
            tap(response => this.SaveStudentSession(response))
        );
    }

    DecodeToken(token: string): LoginStudentResponse {
        const decoded: any = jwtDecode(token);
        const student: LoginStudentResponse = {
            studentId: decoded.sub,
            studentName: decoded.unique_name,
            email: decoded.email,
            roleId: decoded.roleId,
            schoolId: decoded.schoolId || null,
            token: token
        }

        this.SaveStudentSession(student);
        return student;
    }

    RegisterStudent(email: string, firstName: string, lastName: string, password: string): Observable<LoginStudentResponse> {
        return this._httpClient.post<LoginStudentResponse>(`${this.controller}/RegisterStudent`, { email, firstName, lastName, password }).pipe(
            tap(response => this.SaveStudentSession(response))
        );
    }

    public SaveStudentSession(student: LoginStudentResponse) {
        this._studentName = student.studentName;
        this._stateService.schoolId = student.schoolId ?? 0;
        sessionStorage.setItem('student', JSON.stringify(student));
    }

    getJwt() {
        const studentSession = sessionStorage.getItem('student');
        if (!studentSession) return null;
        const student = JSON.parse(studentSession) as LoginStudentResponse;
        return student.token;
    }

    IsAuthenticated() {
        return this.getJwt() !== null;
    }

    LogOut() {
        sessionStorage.removeItem('student');
    }

    recoverPassword(email: string) {
        return this._httpClient.post(`${this.controller}/RecoverPassword?Email=${email}`, {});
    }







}
